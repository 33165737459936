import { useMemo } from 'react';

const useSortedData = (data, sortColumn, sortType) => {
  return useMemo(() => {
    if (!sortColumn || !sortType || !data.length) return data;

    return [...data].sort((a, b) => {
      let x = a[sortColumn];
      let y = b[sortColumn];

      if (typeof x === 'string') {
        x = x.charCodeAt();
      }
      if (typeof y === 'string') {
        y = y.charCodeAt();
      }

      return sortType === 'asc' ? x - y : y - x;
    });
  }, [data, sortColumn, sortType]);
};

export default useSortedData;
